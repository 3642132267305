<template>
  <div class="iotManagePage">
    <!-- 顶部 -->
    <div class="topView" style="display: block; padding-bottom: 20px">
      <div class="searchBox">
        <div class="btn_box">
          <el-button type="success" size="medium" @click="addDevice(1,'','addForm')" icon="el-icon-circle-plus-outline">添加</el-button>
          <el-button type="danger" size="medium" @click="delEquipments" icon="el-icon-remove-outline">删除</el-button>
          <el-button icon="el-icon-wallet" type="primary" size="medium"
            @click="$router.push('/platform/deviceManage/importDevice')">导入设备信息</el-button>
        </div>
        <!-- form表单 -->
        <el-form class="manageForm" :model="manageForm" :inline="true">
          <div>
            <el-form-item label="绑定车牌" prop="VehicleCarNumber" label-width="86px">
              <el-input class="fromInp" v-model="manageForm.VehicleCarNumber" placeholder="输入车牌号"></el-input>
            </el-form-item>
            <el-form-item label="物联设备编号" prop="IMEI">
              <el-input class="fromInp" v-model="manageForm.IMEI" placeholder="输入设备编号"></el-input>
            </el-form-item>
            <el-form-item label="工作状态" prop="VehicleCarNumber" label-width="96px">
              <el-select  v-model="manageForm.equipStatus" placeholder="请选择工作状态" style="width:202px;">
                <el-option style="width:202px;" v-for="(item,index) in facilitytype" :key="index" :value="item.Code" :label="item.Name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="padding-left: 16px">
                <el-button type="primary" size="medium" @click="search('manageForm')" icon="el-icon-search">搜索</el-button>
                <el-button type="primary" size="medium" @click="resetForm('manageForm')" icon="el-icon-delete">清空</el-button>
            </el-form-item>
          </div>
        </el-form>
      </div>
    <!-- 设备信息管理 @selection-change="tableSelectionChange"-->
      <el-table 
        :data="tableData" 
        v-loading="loading"
        :header-cell-style="{background:'#f0f0f0',color:'#666'}"
        @select="selectChange"
        @select-all="selectChange">
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
        <el-table-column align="center" prop="IMEI" label="设备编号" width="120"></el-table-column>
        <el-table-column align="center" prop="EquipTypeName" label="设备类型" width="100"></el-table-column>
        <el-table-column align="center" prop="Note" label="备注" width="110"></el-table-column>
        <el-table-column align="center" prop="EquipOwnerName" label="设备所有人" width="180"></el-table-column>
        <el-table-column align="center" prop="EquipSt" label="设备工作状态" width="110">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.EquipSt === '空闲'" type="info">{{scope.row.EquipSt}}</el-tag>
            <el-tag v-else-if="scope.row.EquipSt === '工作中'" type="success">{{scope.row.EquipSt}}</el-tag>
            <el-tag v-else-if="scope.row.EquipSt === '故障'" type="danger">{{scope.row.EquipSt}}</el-tag> 
            <el-tag v-else type="warning">{{scope.row.EquipSt}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VehicleCarNumber" label="绑定车牌号" width="100"></el-table-column>
        <el-table-column align="center" prop="CarNumberType" label="牌照类型" width="120"></el-table-column>
        <el-table-column align="center" prop="CarType" label="车辆类型" width="200"></el-table-column>
        <el-table-column align="center" prop="CarType" label="车辆归属类型" width="200"></el-table-column>
        <el-table-column align="center" prop="DriverName" label="绑定司机" width="120"></el-table-column>
        <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="150"></el-table-column>
        <el-table-column align="center" prop="FaultDesc" label="异常原因" width="150"></el-table-column>
        <el-table-column align="center" fixed="right" width="330" label="操作">
          <template slot-scope="scope"  style="display: flex;justify-content: center;">
            <el-button type="success" icon="el-icon-circle-check" v-if="scope.row.VehicleCarNumber == null && scope.row.DriverName == null" size="small" @click="bindClick('bind',scope.row)">绑定</el-button>
            <el-button type="danger" icon="el-icon-circle-close" v-if="scope.row.VehicleCarNumber != null || scope.row.DriverName != null" size="small" @click="bindClick('unbind',scope.row)">解绑</el-button>
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-document">操作记录</el-button>
            <el-button type="primary" icon="el-icon-edit-outline" size="small" @click="addDevice('2',scope.row,'addForm')">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
    <el-pagination 
      class="pagination" 
      style="margin-top: 30px;"
      @current-change="handleCurrentChange" 
      :current-page.sync="pagination.page" 
      :page-size="pagination.pagesize" 
      layout="total, prev, pager, next" 
      :total="pagination.total">
    </el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog class="vehicleType" :visible.sync="flag.showAddDialog" width="540px" :before-close="handleClose">
      <span slot="title">{{dialog.title}}</span>
      <div class="body">
        <el-form class="addForm" label-width="118px"
         :model="addForm" :rules="addRule" ref="addForm" :inline="true" style="margin-left: 30px;">
          <el-form-item label="设备类型" prop="EquipTypeID">
            <el-select v-model="addForm.EquipTypeID" placeholder="请选择设备类型" style="width:304px;">
              <el-option v-for="(item,index) in facilitylist" :key="index" :value="item.Code" :label="item.Name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="物联设备编号" prop="IMEI">
            <el-input v-model="addForm.IMEI" style="width:304px;" placeholder="请输入证件号"></el-input>
          </el-form-item>
          <el-form-item :label="addForm.EquipTypeID == '02'?'车牌号':'司机手机号'" prop="BindID">
            <el-input v-model="addForm.BindID" style="width:304px;" :placeholder="addForm.EquipTypeID == '02'?'请输入车牌号':'请输入司机手机号'"></el-input>
          </el-form-item>
          <!-- <el-form-item label="设备所有人" prop="EquipOwner" label-width="116px">
            <el-input v-model="addForm.EquipOwner" style="width:204px;" disabled placeholder="请输入所有人"></el-input>
          </el-form-item> -->
          <el-form-item label="工作状态" prop="EquipStatus">
            <el-select  v-model="addForm.EquipStatus" placeholder="请选择设备工作状态" style="width:304px;">
              <el-option v-for="(item,index) in facilitytype" :key="index" :value="item.Code" :label="item.Name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  label="操作时间" prop="EquipRegDate">
            <el-date-picker disabled style="width:304px;" v-model="addForm.EquipRegDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期范围"></el-date-picker>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="addForm.Note" style="width:304px;" type="textarea" placeholder="请输入备注"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="primary" size="medium" @click="addfacility('addForm')">确认</el-button>
        <el-button size="medium" @click="closeDia">取消</el-button>
      </div>
    </el-dialog>
    <!-- 绑定/解绑弹窗 -->
    <el-dialog :visible.sync="flag.operation" width="500px" class="deep_dialog">
      <span slot="title">{{flag.title}}</span>
      <div class="dialogBox">
        <strong>设备编号：</strong> <u>{{facilityInfo.IMEI}}</u><br/>
        <strong>设备类型：</strong> <u>{{facilityInfo.EquipTypeName}}</u><br/>
        <strong>设备所有人：</strong> <u>{{facilityInfo.EquipOwnerName}}</u>  
      </div>
      <div class="inpBox">
        <div class="dialogText"><strong>{{facilityInfo.EquipTypeName == '方众魔盒'?'车牌号':'司机手机号'}}:</strong></div>
        <el-input style="width:300px"
          class="inp"
          :placeholder="facilityInfo.EquipTypeName == '方众魔盒'?'请输入车牌号':'请输入司机手机号'"
          v-model="facilityValue"
          clearable
          :disabled="disabled">
        </el-input>
      </div>
      <div style="width: 82%;text-align: center;margin:20px 0 0 10%;" v-if="disabled">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          placeholder="请输入解绑原因，让我们更好服务您"
          v-model="textarea">
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="bindFacility">确 定</el-button>
        <el-button @click="flag.operation = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveEquipments,equipmentsList,delEquipmentsInfo,BindUntieInfo,
  getGoodsOwnerInfo, editEquipments
} from '@/api/platform/deviceManage/index';
import {getDataDict} from '@/api/common/common.js'
import { _getName } from '@/utils/storage'
import { mapGetters, mapMutations } from "vuex";
import verify from '@/utils/verify.js'
export default {
  data(){
    return{
      loading: false, // 表格加载
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0,//总条数
      },
      addRule:{
        EquipTypeID: [{ required: true, message: '请选择设备类型', trigger: 'change' }],
        IMEI: [{ required: true, message: '请输入设备编号', trigger: 'change' }],
        // BindID: [{ required: true, message: '请输入', trigger: 'change' }],
        // EquipOwner: [{ required: true, message: '请输入设备所有人', trigger: 'change' }],
        EquipStatus: [{ required: true, message: '请选择工作状态', trigger: 'change' }],
        // EquipRegDate: [{ required: true, message: '请选择操作时间', trigger: 'change' }]
      },
      addForm: {
        EquipTypeID: "02",
        EquipOwner:'1',
        EquipRegDate:new Date(),
        BindID:'',
        IMEI:'',
        EquipStatus: 0,
        OperatorID:0,
        Note:''
      },
      // 弹窗管理
      flag: {
        //是否显示添加设备信息
        showAddDialog: false,
        operation:false,
        title:''
      },
      // 表格数据
      tableData:[],
      // 筛选条件数据
      manageForm:{
        VehicleCarNumber:'',
        IMEI:'',
        DriverPhone:'',
        EquipOwner: '',
        time: [],
        equipStatus: '',
        pageIndex: 1, //当前所处的页码
        pageSize: 10, //每次请求的数量
      },
      // 设备类型
      facilitylist:[],
      // 设备状态
      facilitytype:[],
      // 删除设备数据
      dataAyy:[],
      // 设备信息
      facilityInfo:'',
      // 设备号
      facilityValue:'',
      // 解绑设备是否禁用输入框
      disabled:false,
      // 解绑原因
      textarea:'',
      // 编辑的信息
      editInfo: {},
      // 判断是添加还是编辑  1添加 2编辑
      isAdd: '1',
      dialog: {},
    }
  },
  created(){
    // 获取设备类型
    this.getDataDict(17);
    // 设备状态
    this.getDataDict(18);
    // 获取设备列表
    this.equipmentsList();
    // getGoodsOwnerInfo().then(res => {
    //   if(res.enterPrise != null){
    //     this.addForm.EquipOwner = res.enterPrise.EnterpriseFullName
    //   }
    // })
  },
  computed: {
    ...mapGetters(["getName"]),
  },
  methods:{
    // 添加设备弹框
    addDevice(index,row,addForm) {
      this.isAdd = index
      this.flag.showAddDialog = true;
      if(index == 1){
        this.addForm = {
              EquipTypeID:'02',
         //     EquipOwner:'3',
              EquipRegDate:new Date(),
              BindID:'',
              IMEI:'',
              EquipStatus:'0',
              OperatorID:0,
              Note:''
            }
        this.dialog.title = '添加设备';
      } else if(index == 2){
        this.addForm.EquipTypeID = row.EquipTypeID
        this.addForm.IMEI = row.IMEI
        this.addForm.EquipOwner = row.EquipOwnerName
        this.addForm.VehicleCarNumber = row.VehicleCarNumber
        this.addForm.EquipStatus = row.EquipStatus
        this.addForm.BindID = row.DriverPhone
        this.addForm.Note = row.Note
        this.editInfo = row
        this.dialog.title = '编辑设备'
      }
    },
    handleClose(done){
      done();
      this.$refs['addForm'].resetFields();
    },
    closeDia() {
      this.flag.showAddDialog = false;
      this.$refs['addForm'].resetFields();
    },
    // 绑定/解绑设备
    bindClick(type,row){
      this.facilityInfo = row
      if(type == 'bind'){
        this.flag.title = '绑定设备'
        this.facilityValue = ''
        this.disabled = false
      }else{
        this.flag.title = '解绑设备'
        this.facilityValue = row.EquipTypeName == '方众魔盒'?row.VehicleCarNumber:row.DriverPhone
        this.disabled = true
      }
      this.flag.operation = true
    },
    // 绑定/解绑设备接口
    bindFacility(){
      let data = {}
      if(this.disabled == false){
        // 绑定设备
        data.type = 0;
        data.BindID = this.facilityValue;
        if(this.facilityInfo.EquipTypeID == '01'){
          if(verify._phone(data.BindID) == false){
            this.$message({
              showClose: true,
              message: '司机手机号输入有误，请重新输入',
              type: 'error'
            });
            return
          }
        }else{
          if(data.BindID == ''){
            this.$message({
              showClose: true,
              message: '车牌号不能为空',
              type: 'error'
            });
            return
          }
        }
      }else{
        // 解绑设备
        data.type = 1;
        data.BindID = this.facilityValue;
        if(this.facilityInfo.EquipTypeID == '01'){
          if(verify._phone(data.BindID) == false){
            this.$message({
              showClose: true,
              message: '司机手机号输入有误，请重新输入',
              type: 'error'
            });
            return
          }
        }else{
          if(data.BindID == ''){
            this.$message({
              showClose: true,
              message: '车牌号不能为空',
              type: 'error'
            });
            return
          }
        }
        if(this.textarea == ''){
          this.$message.error('请填写解绑原因');
          return
        }
      }
      let params = {
        IMEI: this.facilityInfo.IMEI,
        bindID: data.BindID,
        type: data.type,
        remark: this.textarea
      }
      BindUntieInfo(params).then(res=>{
        // 获取设备列表
        this.equipmentsList();
        this.flag.operation = false;
        this.textarea = ''
      })
    },
    // 全选/单选删除设备
    selectChange(val){
      let dataAyy = []
      val.forEach((itam,index,array) => {
        dataAyy.push(itam)
      });
      this.dataAyy = dataAyy
    },
    // 删除设备接口
    delEquipments(){
      if(this.dataAyy.length == 0){
        this.$message({
            type: 'info',
            message: '请选择需要删除的设备'
          });  
      }else{
        this.$confirm('此操作将永久删除所选数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let delArray = []
          this.dataAyy.forEach((item,index)=>{
           delArray[index] = item.IMEI
           
          })
          // 要删除设备的字符串
          let lastArr = delArray.join(',')
          
          delEquipmentsInfo(lastArr).then(res=>{
            // 获取设备列表
            this.equipmentsList();
           
         })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
      }
    },
    // 条件搜索设备列表
    search(){
      this.equipmentsList();
    },
    // 清空搜索条件
    resetForm(){
      this.manageForm = {
        IMEI:'',
        VehicleCarNumber:'',
        DriverPhone: '',
        time: [],
        equipStatus: '',
        EquipOwner: '',
        pageIndex: 1, //当前所处的页码
        pageSize: 10, //每次请求的数量
      }
      this.equipmentsList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.manageForm.pageIndex = e;
      this.equipmentsList();
    },
    // 获取设备列表
    equipmentsList(){
      this.loading =  true
      let params = {
        pageIndex: this.manageForm.pageIndex,
        pageSize: this.manageForm.pageSize,
        vehicleCarNumber: this.manageForm.VehicleCarNumber,
        IMEI: this.manageForm.IMEI,
        SearchWord: '',
      //  driverPhone: this.manageForm.DriverPhone,
       // dateStart: this.manageForm.time[0],
       // dateEnd: this.manageForm.time[1],
      //  EquipOwner: this.manageForm.EquipOwner,
        equipStatus: this.manageForm.equipStatus === '' ? '-1': this.manageForm.equipStatus,
      }
      equipmentsList(JSON.stringify(params)).then(res=>{
        this.tableData = res.data.DataList
        this.pagination.total = Number(res.data.TotalCount)
        this.loading = false
      }).catch(err => {
        if (err.code === 403) {
          this.$router.push('/power/nopermission')
        }
      });
    },
    // 获取设备类型/设备状态
    getDataDict(type){
      getDataDict({type}).then(res=>{
        if(type == 17){
          // 设备类型
          this.facilitylist = res.data
        }else if(type == 18){
          // 设备状态
          this.facilitytype = res.data
        }
      })
    },
    // 添加设备
    addfacility(addForm){
      if(this.isAdd == 1){
        this.$refs[addForm].validate((valid) => {
          if (valid) {
            saveEquipments(JSON.stringify(this.addForm)).then(res=>{
              this.equipmentsList();
              this.$refs[addForm].resetFields();
              this.flag.showAddDialog = false;
            })
          }
        })
      } else if(this.isAdd == 2){
        this.$refs[addForm].validate((valid) => {
        if (valid) {
          let params = {
            EquipTypeID: this.addForm.EquipTypeID,
            IMEI: this.addForm.IMEI,
            EquipRegDate: this.addForm.EquipRegDate,
            BindID: this.addForm.BindID,
           // EquipOwner: this.editInfo.EquipOwner,
            EquipStatus: this.addForm.EquipStatus,
            Note: this.addForm.Note
          } 
          editEquipments(JSON.stringify(params)).then(res=>{
            this.equipmentsList();
            this.$refs[addForm].resetFields();
            this.flag.showAddDialog = false;
          })
        }
      })
      }
    },
    //查看操作记录
    goFacility(item) {
      this.$router.push({
        path: '/platform/deviceManage/facility',
        query: {
          IMEI:item.IMEI,
          EquipTypeID:item.EquipTypeID,
          EquipNO:item.EquipNO,
          EquipType:item.EquipType,
          EquipStatus:item.EquipStatus
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';
.inpBox{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  font-size: 15px;
  margin: 10px 0 0 50px;
  .dialogText{
    vertical-align: top;
    margin: 6px 30px 0 0;
  }
  .inp{
    width: 40%;
  }
}
.dialogBox{
  line-height: 28px;
  padding: 0px 50px;
  strong {
    font-size: 15px;
    margin-right: 30px;
    text-align: right;
  }
}
.iotManagePage{
  .vehicleType {
    ::v-deep .el-dialog__header {
      border-bottom: 1px solid #eee;
    }
    ::v-deep .el-dialog__footer{
      border-top: 1px solid #eee;
      text-align: center;
    }
    ::v-deep .el-dialog__body {
      padding: 20px;
    }
    .addForm {
    display: flex;
    flex-wrap: wrap;
    .el-item {
      width: 50%;
    }
    .el-date-editor {
      // width: 204px !important;
    }
  }
    .choose {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
  .facilityBox{
    margin: 20px 0 0 0;
  }
  .searchBox{
    width: 100%;
    display: block;
    .manageForm{
      width: 100%;
    }
    .btn_box{
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #f0f0f0;
    }
    .topBtn{
      width: 100%;
      border-bottom: 1px solid #e2e2ee;
      padding-bottom: 6px;
    }
  }
}
.pageTitle{
  display: inline-block;
  margin-right: 16px;
}
.driverInfo {
    .body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .hint {
        margin: 20px 0;
        font-size: 13px;
      }
      .download {
        color: $primary;
        cursor: pointer;
      }
    }
  }
.deep_dialog{
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }
  ::v-deep .el-dialog__footer{
    border-top: 1px solid #eee;
    text-align: center;
  }
  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>